/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/MoverQuoteDetails', 'model/ServiceProvider'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./MoverQuoteDetails'), require('./ServiceProvider'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.MoverQuote = factory(root.JedemenagecaRestApi.ApiClient, root.JedemenagecaRestApi.MoverQuoteDetails, root.JedemenagecaRestApi.ServiceProvider);
  }
}(this, function(ApiClient, MoverQuoteDetails, ServiceProvider) {
  'use strict';




  /**
   * The MoverQuote model module.
   * @module model/MoverQuote
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new <code>MoverQuote</code>.
   * Quote for a professional mover, formatted for the User.
   * @alias module:model/MoverQuote
   * @class
   * @param status {module:model/MoverQuote.StatusEnum} Status of the quote.
   */
  var exports = function(status) {
    var _this = this;





    _this['status'] = status;
  };

  /**
   * Constructs a <code>MoverQuote</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MoverQuote} obj Optional instance to populate.
   * @return {module:model/MoverQuote} The populated <code>MoverQuote</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('mover')) {
        obj['mover'] = ServiceProvider.constructFromObject(data['mover']);
      }
      if (data.hasOwnProperty('quote')) {
        obj['quote'] = MoverQuoteDetails.constructFromObject(data['quote']);
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('booked')) {
        obj['booked'] = ApiClient.convertToType(data['booked'], 'Boolean');
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = ApiClient.convertToType(data['status'], 'String');
      }
    }
    return obj;
  }

  /**
   * @member {module:model/ServiceProvider} mover
   */
  exports.prototype['mover'] = undefined;
  /**
   * @member {module:model/MoverQuoteDetails} quote
   */
  exports.prototype['quote'] = undefined;
  /**
   * Unique ID of the `MoverQuote`.
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * If the user booked with this provider.
   * @member {Boolean} booked
   * @default false
   */
  exports.prototype['booked'] = false;
  /**
   * Status of the quote.
   * @member {module:model/MoverQuote.StatusEnum} status
   */
  exports.prototype['status'] = undefined;


  /**
   * Allowed values for the <code>status</code> property.
   * @enum {String}
   * @readonly
   */
  exports.StatusEnum = {
    /**
     * value: "pending"
     * @const
     */
    "pending": "pending",
    /**
     * value: "accepted"
     * @const
     */
    "accepted": "accepted",
    /**
     * value: "refused"
     * @const
     */
    "refused": "refused"  };


  return exports;
}));


