/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/ServiceProvider'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./ServiceProvider'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.ServiceTransfer = factory(root.JedemenagecaRestApi.ApiClient, root.JedemenagecaRestApi.ServiceProvider);
  }
}(this, function(ApiClient, ServiceProvider) {
  'use strict';




  /**
   * The ServiceTransfer model module.
   * @module model/ServiceTransfer
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new <code>ServiceTransfer</code>.
   * A service transfer.
   * @alias module:model/ServiceTransfer
   * @class
   */
  var exports = function() {
    var _this = this;





  };

  /**
   * Constructs a <code>ServiceTransfer</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ServiceTransfer} obj Optional instance to populate.
   * @return {module:model/ServiceTransfer} The populated <code>ServiceTransfer</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('accountNumber')) {
        obj['accountNumber'] = ApiClient.convertToType(data['accountNumber'], 'String');
      }
      if (data.hasOwnProperty('categories')) {
        obj['categories'] = ApiClient.convertToType(data['categories'], ['String']);
      }
      if (data.hasOwnProperty('serviceProvider')) {
        obj['serviceProvider'] = ServiceProvider.constructFromObject(data['serviceProvider']);
      }
      if (data.hasOwnProperty('requestDateTime')) {
        obj['requestDateTime'] = ApiClient.convertToType(data['requestDateTime'], 'String');
      }
    }
    return obj;
  }

  /**
   * Account number at the service provider.
   * @member {String} accountNumber
   */
  exports.prototype['accountNumber'] = undefined;
  /**
   * Categories of services the user has with this provider. Depends on the type of `ServiceProvider`. 
   * @member {Array.<String>} categories
   */
  exports.prototype['categories'] = undefined;
  /**
   * @member {module:model/ServiceProvider} serviceProvider
   */
  exports.prototype['serviceProvider'] = undefined;
  /**
   * The date and time when the user made this request.
   * @member {String} requestDateTime
   */
  exports.prototype['requestDateTime'] = undefined;



  return exports;
}));


