/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/Contact', 'model/Move', 'model/MoverQuoteDetails', 'model/ServiceProvider'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./Contact'), require('./Move'), require('./MoverQuoteDetails'), require('./ServiceProvider'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.MoverQuoteForMover = factory(root.JedemenagecaRestApi.ApiClient, root.JedemenagecaRestApi.Contact, root.JedemenagecaRestApi.Move, root.JedemenagecaRestApi.MoverQuoteDetails, root.JedemenagecaRestApi.ServiceProvider);
  }
}(this, function(ApiClient, Contact, Move, MoverQuoteDetails, ServiceProvider) {
  'use strict';




  /**
   * The MoverQuoteForMover model module.
   * @module model/MoverQuoteForMover
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new <code>MoverQuoteForMover</code>.
   * Quote information formatted for a mover. \&quot;quote\&quot; is available only when the quote status is \&quot;accepted\&quot;, and \&quot;contact\&quot; is available only when the user \&quot;booked\&quot; with this Mover. 
   * @alias module:model/MoverQuoteForMover
   * @class
   */
  var exports = function() {
    var _this = this;








  };

  /**
   * Constructs a <code>MoverQuoteForMover</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MoverQuoteForMover} obj Optional instance to populate.
   * @return {module:model/MoverQuoteForMover} The populated <code>MoverQuoteForMover</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('estimatedDistance')) {
        obj['estimatedDistance'] = ApiClient.convertToType(data['estimatedDistance'], 'Number');
      }
      if (data.hasOwnProperty('move')) {
        obj['move'] = Move.constructFromObject(data['move']);
      }
      if (data.hasOwnProperty('contact')) {
        obj['contact'] = Contact.constructFromObject(data['contact']);
      }
      if (data.hasOwnProperty('quote')) {
        obj['quote'] = MoverQuoteDetails.constructFromObject(data['quote']);
      }
      if (data.hasOwnProperty('mover')) {
        obj['mover'] = ServiceProvider.constructFromObject(data['mover']);
      }
      if (data.hasOwnProperty('estimatedWeight')) {
        obj['estimatedWeight'] = ApiClient.convertToType(data['estimatedWeight'], 'Integer');
      }
      if (data.hasOwnProperty('status')) {
        obj['status'] = ApiClient.convertToType(data['status'], 'String');
      }
    }
    return obj;
  }

  /**
   * Estimated (as the crow flies) distance between the 2 addresses, in km.
   * @member {Number} estimatedDistance
   */
  exports.prototype['estimatedDistance'] = undefined;
  /**
   * @member {module:model/Move} move
   */
  exports.prototype['move'] = undefined;
  /**
   * @member {module:model/Contact} contact
   */
  exports.prototype['contact'] = undefined;
  /**
   * @member {module:model/MoverQuoteDetails} quote
   */
  exports.prototype['quote'] = undefined;
  /**
   * @member {module:model/ServiceProvider} mover
   */
  exports.prototype['mover'] = undefined;
  /**
   * Estimated weight of stuff to move, in kg.
   * @member {Integer} estimatedWeight
   */
  exports.prototype['estimatedWeight'] = undefined;
  /**
   * The status of the quote.
   * @member {module:model/MoverQuoteForMover.StatusEnum} status
   */
  exports.prototype['status'] = undefined;


  /**
   * Allowed values for the <code>status</code> property.
   * @enum {String}
   * @readonly
   */
  exports.StatusEnum = {
    /**
     * value: "pending"
     * @const
     */
    "pending": "pending",
    /**
     * value: "accepted"
     * @const
     */
    "accepted": "accepted",
    /**
     * value: "refused"
     * @const
     */
    "refused": "refused"  };


  return exports;
}));


