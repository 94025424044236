/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/VehicleReservation', 'model/VehicleReservationRequest', 'model/Error', 'model/InlineResponse20013', 'model/InlineResponse20011', 'model/InlineResponse20012'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/VehicleReservation'), require('../model/VehicleReservationRequest'), require('../model/Error'), require('../model/InlineResponse20013'), require('../model/InlineResponse20011'), require('../model/InlineResponse20012'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.VehicleRentalApi = factory(root.JedemenagecaRestApi.ApiClient, root.JedemenagecaRestApi.VehicleReservation, root.JedemenagecaRestApi.VehicleReservationRequest, root.JedemenagecaRestApi.Error, root.JedemenagecaRestApi.InlineResponse20013, root.JedemenagecaRestApi.InlineResponse20011, root.JedemenagecaRestApi.InlineResponse20012);
  }
}(this, function(ApiClient, VehicleReservation, VehicleReservationRequest, Error, InlineResponse20013, InlineResponse20011, InlineResponse20012) {
  'use strict';

  /**
   * VehicleRental service.
   * @module api/VehicleRentalApi
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new VehicleRentalApi. 
   * @alias module:api/VehicleRentalApi
   * @class
   * @param {module:ApiClient} apiClient Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the bookVehicleReservation operation.
     * @callback module:api/VehicleRentalApi~bookVehicleReservationCallback
     * @param {String} error Error message, if any.
     * @param {module:model/VehicleReservation} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Book a reservation for a rental vehicle. 
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {module:model/VehicleReservationRequest} opts.body 
     * @param {module:api/VehicleRentalApi~bookVehicleReservationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/VehicleReservation}
     */
    this.bookVehicleReservation = function(opts, callback) {
      opts = opts || {};
      var postBody = opts['body'];


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = VehicleReservation;

      return this.apiClient.callApi(
        '/service_providers/vehicle_rental/reservations/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getVehicleRentalCostEstimate operation.
     * @callback module:api/VehicleRentalApi~getVehicleRentalCostEstimateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20013} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a cost estimate for the rental of a vehicle. 
     * @param {String} serviceProviderId The ID of the &#x60;ServiceProvider&#x60;.
     * @param {module:model/String} vehicleKind The kind of vehicle.
     * @param {String} pickup The date and time at which the vehicle is to be picked up.
     * @param {String} _return The date and time at which the vehicle is to be returned.
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {module:api/VehicleRentalApi~getVehicleRentalCostEstimateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse20013}
     */
    this.getVehicleRentalCostEstimate = function(serviceProviderId, vehicleKind, pickup, _return, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'serviceProviderId' is set
      if (serviceProviderId == undefined || serviceProviderId == null) {
        throw "Missing the required parameter 'serviceProviderId' when calling getVehicleRentalCostEstimate";
      }

      // verify the required parameter 'vehicleKind' is set
      if (vehicleKind == undefined || vehicleKind == null) {
        throw "Missing the required parameter 'vehicleKind' when calling getVehicleRentalCostEstimate";
      }

      // verify the required parameter 'pickup' is set
      if (pickup == undefined || pickup == null) {
        throw "Missing the required parameter 'pickup' when calling getVehicleRentalCostEstimate";
      }

      // verify the required parameter '_return' is set
      if (_return == undefined || _return == null) {
        throw "Missing the required parameter '_return' when calling getVehicleRentalCostEstimate";
      }


      var pathParams = {
        'serviceProviderId': serviceProviderId
      };
      var queryParams = {
        'vehicleKind': vehicleKind,
        'pickup': pickup,
        'return': _return
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse20013;

      return this.apiClient.callApi(
        '/service_providers/vehicle_rental/{serviceProviderId}/estimate/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getVehicleRentalProviders operation.
     * @callback module:api/VehicleRentalApi~getVehicleRentalProvidersCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20011} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Search for Vehicle Rental providers within the vicinity of a coordinate. Returns a list of providers, with the distance from the coordinate in parameter. 
     * @param {Number} latitude The &#39;latitude&#39; part of the coordinate.
     * @param {Number} longitude The &#39;longitude&#39; part of the coordinate.
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {Integer} opts.radius Limits the search to the radius (in km) from the coordinate. (default to 40)
     * @param {Integer} opts.size The maximum number of results to return. (default to 10)
     * @param {module:api/VehicleRentalApi~getVehicleRentalProvidersCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse20011}
     */
    this.getVehicleRentalProviders = function(latitude, longitude, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'latitude' is set
      if (latitude == undefined || latitude == null) {
        throw "Missing the required parameter 'latitude' when calling getVehicleRentalProviders";
      }

      // verify the required parameter 'longitude' is set
      if (longitude == undefined || longitude == null) {
        throw "Missing the required parameter 'longitude' when calling getVehicleRentalProviders";
      }


      var pathParams = {
      };
      var queryParams = {
        'radius': opts['radius'],
        'latitude': latitude,
        'longitude': longitude,
        'size': opts['size']
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse20011;

      return this.apiClient.callApi(
        '/service_providers/vehicle_rental/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getVehicleReservations operation.
     * @callback module:api/VehicleRentalApi~getVehicleReservationsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20012} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Return the registered user&#39;s vehicle reservations. 
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {module:api/VehicleRentalApi~getVehicleReservationsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse20012}
     */
    this.getVehicleReservations = function(opts, callback) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse20012;

      return this.apiClient.callApi(
        '/service_providers/vehicle_rental/reservations/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
