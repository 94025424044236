/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/Error', 'model/InsuranceQuote', 'model/InlineResponse2003', 'model/InlineResponse2004'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/Error'), require('../model/InsuranceQuote'), require('../model/InlineResponse2003'), require('../model/InlineResponse2004'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.InsuranceApi = factory(root.JedemenagecaRestApi.ApiClient, root.JedemenagecaRestApi.Error, root.JedemenagecaRestApi.InsuranceQuote, root.JedemenagecaRestApi.InlineResponse2003, root.JedemenagecaRestApi.InlineResponse2004);
  }
}(this, function(ApiClient, Error, InsuranceQuote, InlineResponse2003, InlineResponse2004) {
  'use strict';

  /**
   * Insurance service.
   * @module api/InsuranceApi
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new InsuranceApi. 
   * @alias module:api/InsuranceApi
   * @class
   * @param {module:ApiClient} apiClient Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the createInsuranceQuote operation.
     * @callback module:api/InsuranceApi~createInsuranceQuoteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InsuranceQuote} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Creates a quote request for insurance services, for specified provider and specified &#x60;Move&#x60;. 
     * @param {String} moveId The unique ID of the &#x60;Move&#x60; for which the quote request must be sent.
     * @param {String} providerId The unique ID of the &#x60;ServiceProvider&#x60; to which the quote request must be sent.
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {module:api/InsuranceApi~createInsuranceQuoteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InsuranceQuote}
     */
    this.createInsuranceQuote = function(moveId, providerId, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'moveId' is set
      if (moveId == undefined || moveId == null) {
        throw "Missing the required parameter 'moveId' when calling createInsuranceQuote";
      }

      // verify the required parameter 'providerId' is set
      if (providerId == undefined || providerId == null) {
        throw "Missing the required parameter 'providerId' when calling createInsuranceQuote";
      }


      var pathParams = {
        'moveId': moveId
      };
      var queryParams = {
        'providerId': providerId
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InsuranceQuote;

      return this.apiClient.callApi(
        '/moves/{moveId}/insurance/quotes/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getInsuranceProviders operation.
     * @callback module:api/InsuranceApi~getInsuranceProvidersCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2003} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get the insurance providers that do business near the destination address of a &#x60;Move&#x60;. 
     * @param {String} moveId The unique ID of the &#x60;Move&#x60;.
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {module:api/InsuranceApi~getInsuranceProvidersCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse2003}
     */
    this.getInsuranceProviders = function(moveId, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'moveId' is set
      if (moveId == undefined || moveId == null) {
        throw "Missing the required parameter 'moveId' when calling getInsuranceProviders";
      }


      var pathParams = {
        'moveId': moveId
      };
      var queryParams = {
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse2003;

      return this.apiClient.callApi(
        '/moves/{moveId}/insurance/providers/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getInsuranceQuotes operation.
     * @callback module:api/InsuranceApi~getInsuranceQuotesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2004} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get the quote requests for insurance, for a specific &#x60;Move&#x60;. 
     * @param {String} moveId The unique ID of the &#x60;Move&#x60; for which the quotes are requested.
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {module:api/InsuranceApi~getInsuranceQuotesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse2004}
     */
    this.getInsuranceQuotes = function(moveId, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'moveId' is set
      if (moveId == undefined || moveId == null) {
        throw "Missing the required parameter 'moveId' when calling getInsuranceQuotes";
      }


      var pathParams = {
        'moveId': moveId
      };
      var queryParams = {
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse2004;

      return this.apiClient.callApi(
        '/moves/{moveId}/insurance/quotes/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
