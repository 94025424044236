/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/PriceDetails'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./PriceDetails'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.VehicleRentalEstimatedCost = factory(root.JedemenagecaRestApi.ApiClient, root.JedemenagecaRestApi.PriceDetails);
  }
}(this, function(ApiClient, PriceDetails) {
  'use strict';




  /**
   * The VehicleRentalEstimatedCost model module.
   * @module model/VehicleRentalEstimatedCost
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new <code>VehicleRentalEstimatedCost</code>.
   * Estimated cost for rental of a vehicle. 
   * @alias module:model/VehicleRentalEstimatedCost
   * @class
   */
  var exports = function() {
    var _this = this;












  };

  /**
   * Constructs a <code>VehicleRentalEstimatedCost</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/VehicleRentalEstimatedCost} obj Optional instance to populate.
   * @return {module:model/VehicleRentalEstimatedCost} The populated <code>VehicleRentalEstimatedCost</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('pricePerKm')) {
        obj['pricePerKm'] = ApiClient.convertToType(data['pricePerKm'], 'Number');
      }
      if (data.hasOwnProperty('unlimitedKm')) {
        obj['unlimitedKm'] = ApiClient.convertToType(data['unlimitedKm'], 'Boolean');
      }
      if (data.hasOwnProperty('vehicleCategoryLabel')) {
        obj['vehicleCategoryLabel'] = ApiClient.convertToType(data['vehicleCategoryLabel'], 'String');
      }
      if (data.hasOwnProperty('vehicleCategory')) {
        obj['vehicleCategory'] = ApiClient.convertToType(data['vehicleCategory'], 'String');
      }
      if (data.hasOwnProperty('reservationInformation')) {
        obj['reservationInformation'] = ApiClient.convertToType(data['reservationInformation'], 'String');
      }
      if (data.hasOwnProperty('baseKm')) {
        obj['baseKm'] = ApiClient.convertToType(data['baseKm'], 'Integer');
      }
      if (data.hasOwnProperty('baseDays')) {
        obj['baseDays'] = ApiClient.convertToType(data['baseDays'], 'Integer');
      }
      if (data.hasOwnProperty('basePrice')) {
        obj['basePrice'] = ApiClient.convertToType(data['basePrice'], 'Number');
      }
      if (data.hasOwnProperty('channel')) {
        obj['channel'] = ApiClient.convertToType(data['channel'], 'String');
      }
      if (data.hasOwnProperty('pricePerDay')) {
        obj['pricePerDay'] = ApiClient.convertToType(data['pricePerDay'], 'Number');
      }
      if (data.hasOwnProperty('priceDetails')) {
        obj['priceDetails'] = PriceDetails.constructFromObject(data['priceDetails']);
      }
    }
    return obj;
  }

  /**
   * Amount to pay per km driven.
   * @member {Number} pricePerKm
   */
  exports.prototype['pricePerKm'] = undefined;
  /**
   * If 'unlimited km' is included.
   * @member {Boolean} unlimitedKm
   */
  exports.prototype['unlimitedKm'] = undefined;
  /**
   * The localized label of the vehicle category.
   * @member {String} vehicleCategoryLabel
   */
  exports.prototype['vehicleCategoryLabel'] = undefined;
  /**
   * The code of the vehicle category.
   * @member {String} vehicleCategory
   */
  exports.prototype['vehicleCategory'] = undefined;
  /**
   * An encoded string that contains all the information required to make a reservation.
   * @member {String} reservationInformation
   */
  exports.prototype['reservationInformation'] = undefined;
  /**
   * Number of km included in the package.
   * @member {Integer} baseKm
   */
  exports.prototype['baseKm'] = undefined;
  /**
   * Number of days included in the package.
   * @member {Integer} baseDays
   */
  exports.prototype['baseDays'] = undefined;
  /**
   * The base price for the package.
   * @member {Number} basePrice
   */
  exports.prototype['basePrice'] = undefined;
  /**
   * Channel through which the reservation will be made.
   * @member {String} channel
   */
  exports.prototype['channel'] = undefined;
  /**
   * Amount to pay for each extra day.
   * @member {Number} pricePerDay
   */
  exports.prototype['pricePerDay'] = undefined;
  /**
   * @member {module:model/PriceDetails} priceDetails
   */
  exports.prototype['priceDetails'] = undefined;



  return exports;
}));


