/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.ServiceTransferRequest = factory(root.JedemenagecaRestApi.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The ServiceTransferRequest model module.
   * @module model/ServiceTransferRequest
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new <code>ServiceTransferRequest</code>.
   * A request for service transfer.
   * @alias module:model/ServiceTransferRequest
   * @class
   * @param categories {Array.<String>} Categories of services the user has with this provider. Depends on the type of `ServiceProvider`. 
   * @param serviceProviderId {String} Unique ID of the service provider.
   */
  var exports = function(categories, serviceProviderId) {
    var _this = this;




    _this['categories'] = categories;



    _this['serviceProviderId'] = serviceProviderId;
  };

  /**
   * Constructs a <code>ServiceTransferRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ServiceTransferRequest} obj Optional instance to populate.
   * @return {module:model/ServiceTransferRequest} The populated <code>ServiceTransferRequest</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('phoneNumber')) {
        obj['phoneNumber'] = ApiClient.convertToType(data['phoneNumber'], 'String');
      }
      if (data.hasOwnProperty('name')) {
        obj['name'] = ApiClient.convertToType(data['name'], 'String');
      }
      if (data.hasOwnProperty('brokerName')) {
        obj['brokerName'] = ApiClient.convertToType(data['brokerName'], 'String');
      }
      if (data.hasOwnProperty('categories')) {
        obj['categories'] = ApiClient.convertToType(data['categories'], ['String']);
      }
      if (data.hasOwnProperty('accountNumber')) {
        obj['accountNumber'] = ApiClient.convertToType(data['accountNumber'], 'String');
      }
      if (data.hasOwnProperty('birthDate')) {
        obj['birthDate'] = ApiClient.convertToType(data['birthDate'], 'Date');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = ApiClient.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('serviceProviderId')) {
        obj['serviceProviderId'] = ApiClient.convertToType(data['serviceProviderId'], 'String');
      }
    }
    return obj;
  }

  /**
   * Phone number registered with this provider's account.
   * @member {String} phoneNumber
   */
  exports.prototype['phoneNumber'] = undefined;
  /**
   * Name of the account's administrator.
   * @member {String} name
   */
  exports.prototype['name'] = undefined;
  /**
   * Name of the broker (optional).
   * @member {String} brokerName
   */
  exports.prototype['brokerName'] = undefined;
  /**
   * Categories of services the user has with this provider. Depends on the type of `ServiceProvider`. 
   * @member {Array.<String>} categories
   */
  exports.prototype['categories'] = undefined;
  /**
   * Account number at the service provider.
   * @member {String} accountNumber
   */
  exports.prototype['accountNumber'] = undefined;
  /**
   * Birth date of the person registered with this provider's account.
   * @member {Date} birthDate
   */
  exports.prototype['birthDate'] = undefined;
  /**
   * Email address registered with this provider's account.
   * @member {String} email
   */
  exports.prototype['email'] = undefined;
  /**
   * Unique ID of the service provider.
   * @member {String} serviceProviderId
   */
  exports.prototype['serviceProviderId'] = undefined;



  return exports;
}));


