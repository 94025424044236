import User from '../services/user'

export default {
	requireGuest (to, from, next) {
		// will stop the routing
		next(!User.isAuthenticated());
	},
	requireUser (to, from, next) {
		// will login and come back
		console.log(User.isAuthenticated())
		next(User.isAuthenticated() ? true : {
			path: '/login',
			query: {
				redirect: to.fullPath
			}
		});
	}
};