/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/Body', 'model/ServiceTransfer', 'model/Error', 'model/InlineResponse20014', 'model/InlineResponse2003', 'model/InlineResponse2006', 'model/Body1'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/Body'), require('../model/ServiceTransfer'), require('../model/Error'), require('../model/InlineResponse20014'), require('../model/InlineResponse2003'), require('../model/InlineResponse2006'), require('../model/Body1'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.ServiceTransferApi = factory(root.JedemenagecaRestApi.ApiClient, root.JedemenagecaRestApi.Body, root.JedemenagecaRestApi.ServiceTransfer, root.JedemenagecaRestApi.Error, root.JedemenagecaRestApi.InlineResponse20014, root.JedemenagecaRestApi.InlineResponse2003, root.JedemenagecaRestApi.InlineResponse2006, root.JedemenagecaRestApi.Body1);
  }
}(this, function(ApiClient, Body, ServiceTransfer, Error, InlineResponse20014, InlineResponse2003, InlineResponse2006, Body1) {
  'use strict';

  /**
   * ServiceTransfer service.
   * @module api/ServiceTransferApi
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new ServiceTransferApi. 
   * @alias module:api/ServiceTransferApi
   * @class
   * @param {module:ApiClient} apiClient Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the createServiceTransfer operation.
     * @callback module:api/ServiceTransferApi~createServiceTransferCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ServiceTransfer} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create a &#x60;ServiceTransfer&#x60; for a Move. 
     * @param {String} moveId The unique ID of the &#x60;Move&#x60;.
     * @param {module:model/Body} body 
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {module:api/ServiceTransferApi~createServiceTransferCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ServiceTransfer}
     */
    this.createServiceTransfer = function(moveId, body, opts, callback) {
      opts = opts || {};
      var postBody = body;

      // verify the required parameter 'moveId' is set
      if (moveId == undefined || moveId == null) {
        throw "Missing the required parameter 'moveId' when calling createServiceTransfer";
      }

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling createServiceTransfer";
      }


      var pathParams = {
        'moveId': moveId
      };
      var queryParams = {
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = ServiceTransfer;

      return this.apiClient.callApi(
        '/moves/{moveId}/service_transfers/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getInstructions operation.
     * @callback module:api/ServiceTransferApi~getInstructionsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20014} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get instructions for changing your address with a given provider.
     * @param {String} serviceProviderId The ID of the &#x60;ServiceProvider&#x60;.
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {module:api/ServiceTransferApi~getInstructionsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse20014}
     */
    this.getInstructions = function(serviceProviderId, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'serviceProviderId' is set
      if (serviceProviderId == undefined || serviceProviderId == null) {
        throw "Missing the required parameter 'serviceProviderId' when calling getInstructions";
      }


      var pathParams = {
        'serviceProviderId': serviceProviderId
      };
      var queryParams = {
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse20014;

      return this.apiClient.callApi(
        '/service_providers/{serviceProviderId}/transfers/instructions/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getServiceProviders operation.
     * @callback module:api/ServiceTransferApi~getServiceProvidersCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2003} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Search for &#x60;ServiceProvider&#x60;&#39;s that do business in the area of the addresses of a Move. 
     * @param {String} moveId The unique ID of the &#x60;Move&#x60;.
     * @param {module:model/String} category The category of service provider to look for.
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {module:api/ServiceTransferApi~getServiceProvidersCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse2003}
     */
    this.getServiceProviders = function(moveId, category, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'moveId' is set
      if (moveId == undefined || moveId == null) {
        throw "Missing the required parameter 'moveId' when calling getServiceProviders";
      }

      // verify the required parameter 'category' is set
      if (category == undefined || category == null) {
        throw "Missing the required parameter 'category' when calling getServiceProviders";
      }


      var pathParams = {
        'moveId': moveId
      };
      var queryParams = {
        'category': category
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse2003;

      return this.apiClient.callApi(
        '/moves/{moveId}/service_providers/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getServiceTransfers operation.
     * @callback module:api/ServiceTransferApi~getServiceTransfersCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2006} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get the &#x60;ServiceTransfer&#x60;&#39;s for a Move. 
     * @param {String} moveId The unique ID of the &#x60;Move&#x60;.
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {module:model/String} opts.category An optional category of &#x60;ServiceProvider&#x60;&#39;s to limit the results to.
     * @param {module:api/ServiceTransferApi~getServiceTransfersCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse2006}
     */
    this.getServiceTransfers = function(moveId, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'moveId' is set
      if (moveId == undefined || moveId == null) {
        throw "Missing the required parameter 'moveId' when calling getServiceTransfers";
      }


      var pathParams = {
        'moveId': moveId
      };
      var queryParams = {
        'category': opts['category']
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse2006;

      return this.apiClient.callApi(
        '/moves/{moveId}/service_transfers/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the updateServiceTransfer operation.
     * @callback module:api/ServiceTransferApi~updateServiceTransferCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ServiceTransfer} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update a &#x60;ServiceTransfer&#x60;. 
     * @param {String} moveId The unique ID of the &#x60;Move&#x60;.
     * @param {String} serviceTransferId The unique ID of the &#x60;ServiceTransfer&#x60;.
     * @param {module:model/Body1} body 
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {module:api/ServiceTransferApi~updateServiceTransferCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ServiceTransfer}
     */
    this.updateServiceTransfer = function(moveId, serviceTransferId, body, opts, callback) {
      opts = opts || {};
      var postBody = body;

      // verify the required parameter 'moveId' is set
      if (moveId == undefined || moveId == null) {
        throw "Missing the required parameter 'moveId' when calling updateServiceTransfer";
      }

      // verify the required parameter 'serviceTransferId' is set
      if (serviceTransferId == undefined || serviceTransferId == null) {
        throw "Missing the required parameter 'serviceTransferId' when calling updateServiceTransfer";
      }

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling updateServiceTransfer";
      }


      var pathParams = {
        'moveId': moveId,
        'serviceTransferId': serviceTransferId
      };
      var queryParams = {
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = ServiceTransfer;

      return this.apiClient.callApi(
        '/moves/{moveId}/service_transfers/{serviceTransferId}/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
