<template>
    <div data-view="login">
        <div class="login-form-outer">
            <div class="login-form form">
                <a href="/">
                    <img class="logo" src="/dist/images/logo-teem.svg" alt="Teem" />
                </a>

                <float-label :label="$t('email')">
                    <input type="email" id="email" name="email" :placeholder="$t('email')" @keyup.enter="auth">
                </float-label>

                <float-label :label="$t('password')">
                    <input type="password" id="password" name="password" :placeholder="$t('password')" @keyup.enter="auth">
                </float-label>

                <button am-button="secondary" class="signin-button" :class="{ 'is-loading': isLoading }" @click="auth">
                    {{ $t('signin') }}
                    <vue-loaders-css type="ball-scale-multiple"></vue-loaders-css>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
	const sanitizeHtml = require('sanitize-html');

	export default {
		name: 'login',
		data () {
			return {
				isLoading: false,
                emailValue: ''
			}
		},
		methods: {
            auth: function (event) {
	            this.isLoading = true;
            },
			xssFilter: function(value) {
				console.log(this.filter)
			}
		},
		i18n: {
			messages: {
				en: {
					email: 'Email',
					password: 'Password',
					submit: `Sign up`,
					signin: `Sign in`,
					noAccount: `Don't have an account? <strong>Sign up </strong>`,
					forgotPassword: `I forgot my password`,
                },
				fr: {
					email: 'Courriel',
					password: 'Mot de passe',
					submit: `m'inscrire`,
					signin: `Me connecter`,
					noAccount: `Vous n'avez pas de compte? <strong>S'inscrire </strong>`,
					forgotPassword: `J'ai oublié mon mot de passe`,
                }
			}
		}
	}
</script>
<!-- styling for the component -->
<style lang="sass">
    .vfl-label {
        opacity: 1;
    }
</style>