<template>
    <div class="param">
        <p>{{ $t("hello") }}</p>
        <p v-t="'hello'"></p>
        <input type="text" size="30" v-model="UserInput" />
        <input type="button" value="Go to route" @click="GoToRoute()">
    </div>
</template>

<script>
	export default {
		name: 'Param',
		data () {
			return {
				UserInput :''
			}
		},
		methods:{
			GoToRoute : function(){
				this.$router.push({ name: 'Paramdetails', params: { id: this.UserInput }})
			}
		},
		i18n: {
			messages: {
				en: { hello: 'hello' },
				fr: { hello: 'allo' }
			}
		}
	}
</script>