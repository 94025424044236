/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/RenovationProject', 'model/Error', 'model/RenovationProjectRequest', 'model/InlineResponse201'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/RenovationProject'), require('../model/Error'), require('../model/RenovationProjectRequest'), require('../model/InlineResponse201'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.RenovationApi = factory(root.JedemenagecaRestApi.ApiClient, root.JedemenagecaRestApi.RenovationProject, root.JedemenagecaRestApi.Error, root.JedemenagecaRestApi.RenovationProjectRequest, root.JedemenagecaRestApi.InlineResponse201);
  }
}(this, function(ApiClient, RenovationProject, Error, RenovationProjectRequest, InlineResponse201) {
  'use strict';

  /**
   * Renovation service.
   * @module api/RenovationApi
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new RenovationApi. 
   * @alias module:api/RenovationApi
   * @class
   * @param {module:ApiClient} apiClient Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the createRenovationProject operation.
     * @callback module:api/RenovationApi~createRenovationProjectCallback
     * @param {String} error Error message, if any.
     * @param {module:model/RenovationProject} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create a &#x60;RenovationProject&#x60;.
     * @param {String} moveId The unique ID of the &#x60;Move&#x60;
     * @param {Object} opts Optional parameters
     * @param {module:model/RenovationProjectRequest} opts.renovationProject The &#x60;RenovationProjectRequest&#x60;.
     * @param {module:api/RenovationApi~createRenovationProjectCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/RenovationProject}
     */
    this.createRenovationProject = function(moveId, opts, callback) {
      opts = opts || {};
      var postBody = opts['renovationProject'];

      // verify the required parameter 'moveId' is set
      if (moveId == undefined || moveId == null) {
        throw "Missing the required parameter 'moveId' when calling createRenovationProject";
      }


      var pathParams = {
        'moveId': moveId
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = RenovationProject;

      return this.apiClient.callApi(
        '/moves/{moveId}/renovation/projects/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getRenovationProjects operation.
     * @callback module:api/RenovationApi~getRenovationProjectsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse201} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get &#x60;RenovationProject&#x60;s.
     * @param {String} moveId The unique ID of the &#x60;Move&#x60;
     * @param {module:api/RenovationApi~getRenovationProjectsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse201}
     */
    this.getRenovationProjects = function(moveId, callback) {
      var postBody = null;

      // verify the required parameter 'moveId' is set
      if (moveId == undefined || moveId == null) {
        throw "Missing the required parameter 'moveId' when calling getRenovationProjects";
      }


      var pathParams = {
        'moveId': moveId
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse201;

      return this.apiClient.callApi(
        '/moves/{moveId}/renovation/projects/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
