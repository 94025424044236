/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/Address', 'model/Home', 'model/MoveOptions'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./Address'), require('./Home'), require('./MoveOptions'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.Move = factory(root.JedemenagecaRestApi.ApiClient, root.JedemenagecaRestApi.Address, root.JedemenagecaRestApi.Home, root.JedemenagecaRestApi.MoveOptions);
  }
}(this, function(ApiClient, Address, Home, MoveOptions) {
  'use strict';




  /**
   * The Move model module.
   * @module model/Move
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new <code>Move</code>.
   * A planned move. Specifies the address from and to which the user plans to move, as well as the date at which the move is planned. 
   * @alias module:model/Move
   * @class
   * @param _date {Date} Date at which the move is planned.
   * @param toAddress {module:model/Address} 
   * @param fromAddress {module:model/Address} 
   */
  var exports = function(_date, toAddress, fromAddress) {
    var _this = this;



    _this['date'] = _date;


    _this['toAddress'] = toAddress;

    _this['fromAddress'] = fromAddress;
  };

  /**
   * Constructs a <code>Move</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Move} obj Optional instance to populate.
   * @return {module:model/Move} The populated <code>Move</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('isOwnerAtNewAddress')) {
        obj['isOwnerAtNewAddress'] = ApiClient.convertToType(data['isOwnerAtNewAddress'], 'Boolean');
      }
      if (data.hasOwnProperty('fromHome')) {
        obj['fromHome'] = Home.constructFromObject(data['fromHome']);
      }
      if (data.hasOwnProperty('date')) {
        obj['date'] = ApiClient.convertToType(data['date'], 'Date');
      }
      if (data.hasOwnProperty('options')) {
        obj['options'] = MoveOptions.constructFromObject(data['options']);
      }
      if (data.hasOwnProperty('toHome')) {
        obj['toHome'] = Home.constructFromObject(data['toHome']);
      }
      if (data.hasOwnProperty('toAddress')) {
        obj['toAddress'] = Address.constructFromObject(data['toAddress']);
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('fromAddress')) {
        obj['fromAddress'] = Address.constructFromObject(data['fromAddress']);
      }
    }
    return obj;
  }

  /**
   * Indicates if the user will be the owner of the new place (false indicates a tenant).
   * @member {Boolean} isOwnerAtNewAddress
   */
  exports.prototype['isOwnerAtNewAddress'] = undefined;
  /**
   * @member {module:model/Home} fromHome
   */
  exports.prototype['fromHome'] = undefined;
  /**
   * Date at which the move is planned.
   * @member {Date} date
   */
  exports.prototype['date'] = undefined;
  /**
   * @member {module:model/MoveOptions} options
   */
  exports.prototype['options'] = undefined;
  /**
   * @member {module:model/Home} toHome
   */
  exports.prototype['toHome'] = undefined;
  /**
   * @member {module:model/Address} toAddress
   */
  exports.prototype['toAddress'] = undefined;
  /**
   * Unique identifier of the Move.
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * @member {module:model/Address} fromAddress
   */
  exports.prototype['fromAddress'] = undefined;



  return exports;
}));


