<template>
    <div id="about">
        <h1>{{ $t("about.welcome") }}</h1>
        <p :class="[{ active: isActive }]">{{ message }}</p>
        <button @click="reverseMessage">Reverse Message</button>
    </div>
</template>

<script>
	export default {
		name: 'about',
		data () {
			return {
				message: 'Welcome to Your Vue.js App',
				isActive: false
			}
		},
		methods: {
			reverseMessage: function () {
				this.isActive = true;

				this.message = this.message.split('').reverse().join('')
			}
		}
	}
</script>
<!-- styling for the component -->
<style>
    #about {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }
</style>