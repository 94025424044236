<template>
    <div id="app" :view="currentView()">
        <nav class="nav">
            <router-link v-bind:to="'/'">{{ $t("nav.home") }}</router-link>
            <router-link v-bind:to="'/login'">{{ $t("nav.login") }}</router-link>
            <router-link v-bind:to="'/about'">{{ $t("nav.about") }}</router-link>
            <router-link v-bind:to="'/param'">{{ $t("nav.param") }}</router-link>
        </nav>

        <div class="lang">
            <div v-if="this.$locale.current() !== 'en'" @click="switchLang('en')">
                English
            </div>
            <div v-if="this.$locale.current() !== 'fr'" @click="switchLang('fr')">
                Français
            </div>
        </div>

        <!-- the router outlet, where all matched components would ber viewed -->
        <transition name="component-fade" mode="out-in">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
	export default {
		name: 'app',
		methods: {
			beforeEnter: function(el) {
				//const element = document.querySelectorAll('section');
				//Velocity(element, { opacity: 0  }, { duration: 2000 })
			},
			beforeLeave: function(el) {
				//const element = document.querySelectorAll('section');
				//Velocity(element, { opacity: 1  }, { duration: 2000 })
			},
			switchLang: function(newLang){
				this.$locale.change(newLang)
				localStorage.setItem('lang', newLang);
			},
            currentView: function() {
				const currentView = this.$route.path.replace('/', '');

				return currentView;
            }
		}
	}
</script>
<!-- styling for the component -->
<style lang="sass">
    @import './utils/variables';
    @import './utils/media-queries';

    @import './layout/normalize';
    @import './layout/typography';
    @import './layout/colors';
    @import './layout/base';

    @import './ui/transitions';

    @import './components/nav';
    @import './components/form';
    @import './components/button';
    @import './components/loaders';

    @import './views/login';
</style>