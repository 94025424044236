import RouterGuard from '../router/routerGuard'

import Hello from '../components/Hello.vue';
import Login from '../components/Login.vue';
import About from '../components/About.vue';
import Param from '../components/Param.vue';
import ParamDetails from '../components/Paramedetails.vue';
import NotFound from '../components/404.vue';


export default [
	{
		path: '/', component: Hello
	},
	{
		path: '/login', component: Login
	},
	{
		path: '/about', component: About,
		meta: { adminOnly: true }
	},
	{
		path: '/param', component: Param
	},
	{
		path: '/Paramdetails/:id', component: ParamDetails, name: 'Paramdetails'
	},
	{
		path: '*', component: NotFound
	}
];