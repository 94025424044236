/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/ServiceProvider'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./ServiceProvider'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.VehicleReservation = factory(root.JedemenagecaRestApi.ApiClient, root.JedemenagecaRestApi.ServiceProvider);
  }
}(this, function(ApiClient, ServiceProvider) {
  'use strict';




  /**
   * The VehicleReservation model module.
   * @module model/VehicleReservation
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new <code>VehicleReservation</code>.
   * Vehicle reservation details.
   * @alias module:model/VehicleReservation
   * @class
   */
  var exports = function() {
    var _this = this;














  };

  /**
   * Constructs a <code>VehicleReservation</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/VehicleReservation} obj Optional instance to populate.
   * @return {module:model/VehicleReservation} The populated <code>VehicleReservation</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('pricePerKm')) {
        obj['pricePerKm'] = ApiClient.convertToType(data['pricePerKm'], 'Number');
      }
      if (data.hasOwnProperty('providerReservationNumber')) {
        obj['providerReservationNumber'] = ApiClient.convertToType(data['providerReservationNumber'], 'String');
      }
      if (data.hasOwnProperty('priceTotal')) {
        obj['priceTotal'] = ApiClient.convertToType(data['priceTotal'], 'Number');
      }
      if (data.hasOwnProperty('vehicleCategoryLabel')) {
        obj['vehicleCategoryLabel'] = ApiClient.convertToType(data['vehicleCategoryLabel'], 'String');
      }
      if (data.hasOwnProperty('vehicleCategory')) {
        obj['vehicleCategory'] = ApiClient.convertToType(data['vehicleCategory'], 'String');
      }
      if (data.hasOwnProperty('returnServiceProvider')) {
        obj['returnServiceProvider'] = ServiceProvider.constructFromObject(data['returnServiceProvider']);
      }
      if (data.hasOwnProperty('returnDatetime')) {
        obj['returnDatetime'] = ApiClient.convertToType(data['returnDatetime'], 'String');
      }
      if (data.hasOwnProperty('requestDatetime')) {
        obj['requestDatetime'] = ApiClient.convertToType(data['requestDatetime'], 'String');
      }
      if (data.hasOwnProperty('channel')) {
        obj['channel'] = ApiClient.convertToType(data['channel'], 'String');
      }
      if (data.hasOwnProperty('reservationNumber')) {
        obj['reservationNumber'] = ApiClient.convertToType(data['reservationNumber'], 'String');
      }
      if (data.hasOwnProperty('pickupServiceProvider')) {
        obj['pickupServiceProvider'] = ServiceProvider.constructFromObject(data['pickupServiceProvider']);
      }
      if (data.hasOwnProperty('pricePerDay')) {
        obj['pricePerDay'] = ApiClient.convertToType(data['pricePerDay'], 'Number');
      }
      if (data.hasOwnProperty('pickupDatetime')) {
        obj['pickupDatetime'] = ApiClient.convertToType(data['pickupDatetime'], 'String');
      }
    }
    return obj;
  }

  /**
   * The cost per km driven.
   * @member {Number} pricePerKm
   */
  exports.prototype['pricePerKm'] = undefined;
  /**
   * Reservation number provided by the ServiceProvider.
   * @member {String} providerReservationNumber
   */
  exports.prototype['providerReservationNumber'] = undefined;
  /**
   * Estimated total cost.
   * @member {Number} priceTotal
   */
  exports.prototype['priceTotal'] = undefined;
  /**
   * The localized label of the vehicle category.
   * @member {String} vehicleCategoryLabel
   */
  exports.prototype['vehicleCategoryLabel'] = undefined;
  /**
   * Vehicle category.
   * @member {String} vehicleCategory
   */
  exports.prototype['vehicleCategory'] = undefined;
  /**
   * @member {module:model/ServiceProvider} returnServiceProvider
   */
  exports.prototype['returnServiceProvider'] = undefined;
  /**
   * Date and time when the vehicle will be returned.
   * @member {String} returnDatetime
   */
  exports.prototype['returnDatetime'] = undefined;
  /**
   * Date and time when the reservation was requested.
   * @member {String} requestDatetime
   */
  exports.prototype['requestDatetime'] = undefined;
  /**
   * Channel through which the request was forwarded to the provider.
   * @member {module:model/VehicleReservation.ChannelEnum} channel
   */
  exports.prototype['channel'] = undefined;
  /**
   * Reservation number provided by JeDemenage.
   * @member {String} reservationNumber
   */
  exports.prototype['reservationNumber'] = undefined;
  /**
   * @member {module:model/ServiceProvider} pickupServiceProvider
   */
  exports.prototype['pickupServiceProvider'] = undefined;
  /**
   * The cost per day.
   * @member {Number} pricePerDay
   */
  exports.prototype['pricePerDay'] = undefined;
  /**
   * Date and time when the vehicle will be picked up.
   * @member {String} pickupDatetime
   */
  exports.prototype['pickupDatetime'] = undefined;


  /**
   * Allowed values for the <code>channel</code> property.
   * @enum {String}
   * @readonly
   */
  exports.ChannelEnum = {
    /**
     * value: "email"
     * @const
     */
    "email": "email",
    /**
     * value: "api"
     * @const
     */
    "api": "api"  };


  return exports;
}));


