import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from './message';

Vue.use(VueI18n);

const dateTimeFormats = {
	'en': {
		short: {
			year: 'numeric', month: 'short', day: 'numeric'
		},
		long: {
			year: 'numeric', month: 'short', day: 'numeric',
			weekday: 'short', hour: 'numeric', minute: 'numeric',
			hour12: true
		}
	},
	'fr': {
		short: {
			day: 'numeric', month: 'short', year: 'numeric'
		},
		long: {
			day: 'numeric', month: 'short', year: 'numeric',
			weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: false
		}
	}
};

if(!localStorage.getItem('lang')) {
	localStorage.setItem('lang', 'fr');
}

let currentLang = localStorage.getItem('lang');

const i18n = new VueI18n({
	locale: `${currentLang}`,
	fallbackLocale: 'fr',
	dateTimeFormats,
	messages
});

Vue.prototype.$locale = {
	change (lang) {
		i18n.locale = lang
	},
	current () {
		return i18n.locale
	}
}

export default i18n;
