/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/RealtorPartner'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./RealtorPartner'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.RealtorPartnerEvent = factory(root.JedemenagecaRestApi.ApiClient, root.JedemenagecaRestApi.RealtorPartner);
  }
}(this, function(ApiClient, RealtorPartner) {
  'use strict';




  /**
   * The RealtorPartnerEvent model module.
   * @module model/RealtorPartnerEvent
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new <code>RealtorPartnerEvent</code>.
   * An action done by a Realtor&#39;s client in relation with one of the Realtor&#39;s partners.
   * @alias module:model/RealtorPartnerEvent
   * @class
   * @param action {String} The code of the action that was performed.
   * @param actionLabel {String} A textual description of the action.
   * @param _date {Date} The date when the action was performed.
   * @param partner {module:model/RealtorPartner} 
   */
  var exports = function(action, actionLabel, _date, partner) {
    var _this = this;

    _this['action'] = action;
    _this['actionLabel'] = actionLabel;
    _this['date'] = _date;
    _this['partner'] = partner;
  };

  /**
   * Constructs a <code>RealtorPartnerEvent</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/RealtorPartnerEvent} obj Optional instance to populate.
   * @return {module:model/RealtorPartnerEvent} The populated <code>RealtorPartnerEvent</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('action')) {
        obj['action'] = ApiClient.convertToType(data['action'], 'String');
      }
      if (data.hasOwnProperty('actionLabel')) {
        obj['actionLabel'] = ApiClient.convertToType(data['actionLabel'], 'String');
      }
      if (data.hasOwnProperty('date')) {
        obj['date'] = ApiClient.convertToType(data['date'], 'Date');
      }
      if (data.hasOwnProperty('partner')) {
        obj['partner'] = RealtorPartner.constructFromObject(data['partner']);
      }
    }
    return obj;
  }

  /**
   * The code of the action that was performed.
   * @member {String} action
   */
  exports.prototype['action'] = undefined;
  /**
   * A textual description of the action.
   * @member {String} actionLabel
   */
  exports.prototype['actionLabel'] = undefined;
  /**
   * The date when the action was performed.
   * @member {Date} date
   */
  exports.prototype['date'] = undefined;
  /**
   * @member {module:model/RealtorPartner} partner
   */
  exports.prototype['partner'] = undefined;



  return exports;
}));


