/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/Address'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./Address'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.Contact = factory(root.JedemenagecaRestApi.ApiClient, root.JedemenagecaRestApi.Address);
  }
}(this, function(ApiClient, Address) {
  'use strict';




  /**
   * The Contact model module.
   * @module model/Contact
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new <code>Contact</code>.
   * Contact information, like name and means by which this person can be reached. 
   * @alias module:model/Contact
   * @class
   * @param lastName {String} Surname (or family) name.
   * @param firstName {String} First (or given) name.
   */
  var exports = function(lastName, firstName) {
    var _this = this;




    _this['lastName'] = lastName;
    _this['firstName'] = firstName;



  };

  /**
   * Constructs a <code>Contact</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Contact} obj Optional instance to populate.
   * @return {module:model/Contact} The populated <code>Contact</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('gender')) {
        obj['gender'] = ApiClient.convertToType(data['gender'], 'String');
      }
      if (data.hasOwnProperty('language')) {
        obj['language'] = ApiClient.convertToType(data['language'], 'String');
      }
      if (data.hasOwnProperty('phoneNumber')) {
        obj['phoneNumber'] = ApiClient.convertToType(data['phoneNumber'], 'String');
      }
      if (data.hasOwnProperty('lastName')) {
        obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
      }
      if (data.hasOwnProperty('firstName')) {
        obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('email')) {
        obj['email'] = ApiClient.convertToType(data['email'], 'String');
      }
      if (data.hasOwnProperty('address')) {
        obj['address'] = Address.constructFromObject(data['address']);
      }
    }
    return obj;
  }

  /**
   * Gender (or sex) of the Contact. A single lower-cased letter: f = female; m = male.
   * @member {module:model/Contact.GenderEnum} gender
   */
  exports.prototype['gender'] = undefined;
  /**
   * 2-letter ISO code (lower-cased) of the Contact's spoken language.
   * @member {module:model/Contact.LanguageEnum} language
   */
  exports.prototype['language'] = undefined;
  /**
   * Phone number.
   * @member {String} phoneNumber
   */
  exports.prototype['phoneNumber'] = undefined;
  /**
   * Surname (or family) name.
   * @member {String} lastName
   */
  exports.prototype['lastName'] = undefined;
  /**
   * First (or given) name.
   * @member {String} firstName
   */
  exports.prototype['firstName'] = undefined;
  /**
   * Unique identifier of the Contact.
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * Email address.
   * @member {String} email
   */
  exports.prototype['email'] = undefined;
  /**
   * @member {module:model/Address} address
   */
  exports.prototype['address'] = undefined;


  /**
   * Allowed values for the <code>gender</code> property.
   * @enum {String}
   * @readonly
   */
  exports.GenderEnum = {
    /**
     * value: "f"
     * @const
     */
    "f": "f",
    /**
     * value: "m"
     * @const
     */
    "m": "m"  };

  /**
   * Allowed values for the <code>language</code> property.
   * @enum {String}
   * @readonly
   */
  exports.LanguageEnum = {
    /**
     * value: "en"
     * @const
     */
    "en": "en",
    /**
     * value: "fr"
     * @const
     */
    "fr": "fr"  };


  return exports;
}));


