/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.MoverQuoteDetails = factory(root.JedemenagecaRestApi.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The MoverQuoteDetails model module.
   * @module model/MoverQuoteDetails
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new <code>MoverQuoteDetails</code>.
   * Details of the quote submitted from a professional mover.
   * @alias module:model/MoverQuoteDetails
   * @class
   * @param durationUnit {module:model/MoverQuoteDetails.DurationUnitEnum} The unit of the estimated duration for the job.
   * @param insuranceAmount {Integer} The amount of the insurance of the mover for damage to property.
   * @param numWorkers {Integer} Number of men on the job.
   * @param durationCount {Integer} The number of `durationUnit` estimated for the job.
   * @param price {String} Estimated price for the move.
   */
  var exports = function(durationUnit, insuranceAmount, numWorkers, durationCount, price) {
    var _this = this;


    _this['durationUnit'] = durationUnit;

    _this['insuranceAmount'] = insuranceAmount;
    _this['numWorkers'] = numWorkers;
    _this['durationCount'] = durationCount;
    _this['price'] = price;




  };

  /**
   * Constructs a <code>MoverQuoteDetails</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MoverQuoteDetails} obj Optional instance to populate.
   * @return {module:model/MoverQuoteDetails} The populated <code>MoverQuoteDetails</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('comment')) {
        obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
      }
      if (data.hasOwnProperty('durationUnit')) {
        obj['durationUnit'] = ApiClient.convertToType(data['durationUnit'], 'String');
      }
      if (data.hasOwnProperty('priceType')) {
        obj['priceType'] = ApiClient.convertToType(data['priceType'], 'String');
      }
      if (data.hasOwnProperty('insuranceAmount')) {
        obj['insuranceAmount'] = ApiClient.convertToType(data['insuranceAmount'], 'Integer');
      }
      if (data.hasOwnProperty('numWorkers')) {
        obj['numWorkers'] = ApiClient.convertToType(data['numWorkers'], 'Integer');
      }
      if (data.hasOwnProperty('durationCount')) {
        obj['durationCount'] = ApiClient.convertToType(data['durationCount'], 'Integer');
      }
      if (data.hasOwnProperty('price')) {
        obj['price'] = ApiClient.convertToType(data['price'], 'String');
      }
      if (data.hasOwnProperty('storageSpaceIncluded')) {
        obj['storageSpaceIncluded'] = ApiClient.convertToType(data['storageSpaceIncluded'], 'Boolean');
      }
      if (data.hasOwnProperty('furnitureProtectionIncluded')) {
        obj['furnitureProtectionIncluded'] = ApiClient.convertToType(data['furnitureProtectionIncluded'], 'Boolean');
      }
      if (data.hasOwnProperty('assemblyDisassemblyIncluded')) {
        obj['assemblyDisassemblyIncluded'] = ApiClient.convertToType(data['assemblyDisassemblyIncluded'], 'Boolean');
      }
      if (data.hasOwnProperty('packingUnpackingIncluded')) {
        obj['packingUnpackingIncluded'] = ApiClient.convertToType(data['packingUnpackingIncluded'], 'Boolean');
      }
    }
    return obj;
  }

  /**
   * A comment left by the mover to the attention of the requester of the Quote.
   * @member {String} comment
   */
  exports.prototype['comment'] = undefined;
  /**
   * The unit of the estimated duration for the job.
   * @member {module:model/MoverQuoteDetails.DurationUnitEnum} durationUnit
   */
  exports.prototype['durationUnit'] = undefined;
  /**
   * The type of price provided. Can be an hourly rate or the total estimated amount.
   * @member {module:model/MoverQuoteDetails.PriceTypeEnum} priceType
   */
  exports.prototype['priceType'] = undefined;
  /**
   * The amount of the insurance of the mover for damage to property.
   * @member {Integer} insuranceAmount
   */
  exports.prototype['insuranceAmount'] = undefined;
  /**
   * Number of men on the job.
   * @member {Integer} numWorkers
   */
  exports.prototype['numWorkers'] = undefined;
  /**
   * The number of `durationUnit` estimated for the job.
   * @member {Integer} durationCount
   */
  exports.prototype['durationCount'] = undefined;
  /**
   * Estimated price for the move.
   * @member {String} price
   */
  exports.prototype['price'] = undefined;
  /**
   * If Complimentary Storage Space is included.
   * @member {Boolean} storageSpaceIncluded
   * @default false
   */
  exports.prototype['storageSpaceIncluded'] = false;
  /**
   * If Furniture Protection is included.
   * @member {Boolean} furnitureProtectionIncluded
   * @default false
   */
  exports.prototype['furnitureProtectionIncluded'] = false;
  /**
   * If Assembly/Disassembly is included.
   * @member {Boolean} assemblyDisassemblyIncluded
   * @default false
   */
  exports.prototype['assemblyDisassemblyIncluded'] = false;
  /**
   * If Packing/Unpacking is included.
   * @member {Boolean} packingUnpackingIncluded
   * @default false
   */
  exports.prototype['packingUnpackingIncluded'] = false;


  /**
   * Allowed values for the <code>durationUnit</code> property.
   * @enum {String}
   * @readonly
   */
  exports.DurationUnitEnum = {
    /**
     * value: "hours"
     * @const
     */
    "hours": "hours",
    /**
     * value: "days"
     * @const
     */
    "days": "days"  };

  /**
   * Allowed values for the <code>priceType</code> property.
   * @enum {String}
   * @readonly
   */
  exports.PriceTypeEnum = {
    /**
     * value: "hourly"
     * @const
     */
    "hourly": "hourly",
    /**
     * value: "total"
     * @const
     */
    "total": "total"  };


  return exports;
}));


