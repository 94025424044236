/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.Address = factory(root.JedemenagecaRestApi.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The Address model module.
   * @module model/Address
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new <code>Address</code>.
   * Complete postal address. 
   * @alias module:model/Address
   * @class
   * @param postalCode {String} Postal Code (ex. 'A1B2C3' for canadian addresses).
   * @param street {String} Name of the street.
   * @param civicNumber {Integer} Number identifying uniquely a building on a given street.
   * @param state {String} 2-letter ISO code (lower-cased) of the province or state (ex. qc for Quebec).
   * @param city {String} Name of the city.
   * @param country {String} 2-letter ISO code (lower-cased) of the country (ex. ca for Canada).
   */
  var exports = function(postalCode, street, civicNumber, state, city, country) {
    var _this = this;



    _this['postalCode'] = postalCode;


    _this['street'] = street;
    _this['civicNumber'] = civicNumber;

    _this['state'] = state;
    _this['city'] = city;

    _this['country'] = country;
  };

  /**
   * Constructs a <code>Address</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Address} obj Optional instance to populate.
   * @return {module:model/Address} The populated <code>Address</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('countryLabel')) {
        obj['countryLabel'] = ApiClient.convertToType(data['countryLabel'], 'String');
      }
      if (data.hasOwnProperty('latitude')) {
        obj['latitude'] = ApiClient.convertToType(data['latitude'], 'String');
      }
      if (data.hasOwnProperty('postalCode')) {
        obj['postalCode'] = ApiClient.convertToType(data['postalCode'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('stateLabel')) {
        obj['stateLabel'] = ApiClient.convertToType(data['stateLabel'], 'String');
      }
      if (data.hasOwnProperty('street')) {
        obj['street'] = ApiClient.convertToType(data['street'], 'String');
      }
      if (data.hasOwnProperty('civicNumber')) {
        obj['civicNumber'] = ApiClient.convertToType(data['civicNumber'], 'Integer');
      }
      if (data.hasOwnProperty('extra')) {
        obj['extra'] = ApiClient.convertToType(data['extra'], 'String');
      }
      if (data.hasOwnProperty('state')) {
        obj['state'] = ApiClient.convertToType(data['state'], 'String');
      }
      if (data.hasOwnProperty('city')) {
        obj['city'] = ApiClient.convertToType(data['city'], 'String');
      }
      if (data.hasOwnProperty('longitude')) {
        obj['longitude'] = ApiClient.convertToType(data['longitude'], 'String');
      }
      if (data.hasOwnProperty('country')) {
        obj['country'] = ApiClient.convertToType(data['country'], 'String');
      }
    }
    return obj;
  }

  /**
   * Full name of the country (ex. Canada).
   * @member {String} countryLabel
   */
  exports.prototype['countryLabel'] = undefined;
  /**
   * The latitude of the coordinate at which the Address is located. String representation of a decimal number with a maximum of 6 decimal digits.
   * @member {String} latitude
   */
  exports.prototype['latitude'] = undefined;
  /**
   * Postal Code (ex. 'A1B2C3' for canadian addresses).
   * @member {String} postalCode
   */
  exports.prototype['postalCode'] = undefined;
  /**
   * Unique identifier of the Address.
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * Full name of the province or state (ex. Quebec).
   * @member {String} stateLabel
   */
  exports.prototype['stateLabel'] = undefined;
  /**
   * Name of the street.
   * @member {String} street
   */
  exports.prototype['street'] = undefined;
  /**
   * Number identifying uniquely a building on a given street.
   * @member {Integer} civicNumber
   */
  exports.prototype['civicNumber'] = undefined;
  /**
   * Other information that could be required to identify the address uniquely (ex. apartment number).
   * @member {String} extra
   * @default ''
   */
  exports.prototype['extra'] = '';
  /**
   * 2-letter ISO code (lower-cased) of the province or state (ex. qc for Quebec).
   * @member {String} state
   */
  exports.prototype['state'] = undefined;
  /**
   * Name of the city.
   * @member {String} city
   */
  exports.prototype['city'] = undefined;
  /**
   * The longitude of the coordinate at which the Address is located. String representation of a decimal number with a maximum of 6 decimal digits.
   * @member {String} longitude
   */
  exports.prototype['longitude'] = undefined;
  /**
   * 2-letter ISO code (lower-cased) of the country (ex. ca for Canada).
   * @member {String} country
   */
  exports.prototype['country'] = undefined;



  return exports;
}));


