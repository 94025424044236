/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/MoverQuote', 'model/Error', 'model/InlineResponse2005', 'model/MoverQuoteForMover', 'model/MoverQuoteReply'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/MoverQuote'), require('../model/Error'), require('../model/InlineResponse2005'), require('../model/MoverQuoteForMover'), require('../model/MoverQuoteReply'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.MoverApi = factory(root.JedemenagecaRestApi.ApiClient, root.JedemenagecaRestApi.MoverQuote, root.JedemenagecaRestApi.Error, root.JedemenagecaRestApi.InlineResponse2005, root.JedemenagecaRestApi.MoverQuoteForMover, root.JedemenagecaRestApi.MoverQuoteReply);
  }
}(this, function(ApiClient, MoverQuote, Error, InlineResponse2005, MoverQuoteForMover, MoverQuoteReply) {
  'use strict';

  /**
   * Mover service.
   * @module api/MoverApi
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new MoverApi. 
   * @alias module:api/MoverApi
   * @class
   * @param {module:ApiClient} apiClient Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the bookReservationWithMover operation.
     * @callback module:api/MoverApi~bookReservationWithMoverCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MoverQuote} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Book a reservation for moving services. Returns updated &#x60;MoverQuote&#x60;. 
     * @param {String} quoteId The unique ID of the &#x60;Quote&#x60;.
     * @param {module:api/MoverApi~bookReservationWithMoverCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MoverQuote}
     */
    this.bookReservationWithMover = function(quoteId, callback) {
      var postBody = null;

      // verify the required parameter 'quoteId' is set
      if (quoteId == undefined || quoteId == null) {
        throw "Missing the required parameter 'quoteId' when calling bookReservationWithMover";
      }


      var pathParams = {
        'quoteId': quoteId
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = MoverQuote;

      return this.apiClient.callApi(
        '/movers/quotes/{quoteId}/book/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the createMoverQuotes operation.
     * @callback module:api/MoverApi~createMoverQuotesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2005} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Creates the quote requests for professional movers for a specific &#x60;Move&#x60;. Returns the created quotes. 
     * @param {String} moveId The unique ID of the &#x60;Move&#x60; for which the quote requests must be sent.
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {module:api/MoverApi~createMoverQuotesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse2005}
     */
    this.createMoverQuotes = function(moveId, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'moveId' is set
      if (moveId == undefined || moveId == null) {
        throw "Missing the required parameter 'moveId' when calling createMoverQuotes";
      }


      var pathParams = {
        'moveId': moveId
      };
      var queryParams = {
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse2005;

      return this.apiClient.callApi(
        '/moves/{moveId}/movers/quotes/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getMoverQuotes operation.
     * @callback module:api/MoverApi~getMoverQuotesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2005} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns the quote requests for professional movers for a specific &#x60;Move&#x60;. 
     * @param {String} moveId The unique ID of the &#x60;Move&#x60; for which the quotes are requested.
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {module:api/MoverApi~getMoverQuotesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse2005}
     */
    this.getMoverQuotes = function(moveId, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'moveId' is set
      if (moveId == undefined || moveId == null) {
        throw "Missing the required parameter 'moveId' when calling getMoverQuotes";
      }


      var pathParams = {
        'moveId': moveId
      };
      var queryParams = {
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse2005;

      return this.apiClient.callApi(
        '/moves/{moveId}/movers/quotes/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getQuote operation.
     * @callback module:api/MoverApi~getQuoteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MoverQuoteForMover} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get an existing quote for moving services.
     * @param {String} quoteId The unique ID of the &#x60;Quote&#x60;.
     * @param {String} secret Secret key required to access a quote anonymously.
     * @param {module:api/MoverApi~getQuoteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MoverQuoteForMover}
     */
    this.getQuote = function(quoteId, secret, callback) {
      var postBody = null;

      // verify the required parameter 'quoteId' is set
      if (quoteId == undefined || quoteId == null) {
        throw "Missing the required parameter 'quoteId' when calling getQuote";
      }

      // verify the required parameter 'secret' is set
      if (secret == undefined || secret == null) {
        throw "Missing the required parameter 'secret' when calling getQuote";
      }


      var pathParams = {
        'quoteId': quoteId
      };
      var queryParams = {
        'secret': secret
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = MoverQuoteForMover;

      return this.apiClient.callApi(
        '/movers/quotes/{quoteId}/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the submitQuote operation.
     * @callback module:api/MoverApi~submitQuoteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MoverQuoteForMover} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Submit quote for moving services. 
     * @param {String} quoteId The unique ID of the &#x60;Quote&#x60;.
     * @param {String} secret Secret key required to access a quote anonymously.
     * @param {Object} opts Optional parameters
     * @param {module:model/MoverQuoteReply} opts.body &#x60;MoverQuoteReply&#x60; to submit.
     * @param {module:api/MoverApi~submitQuoteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MoverQuoteForMover}
     */
    this.submitQuote = function(quoteId, secret, opts, callback) {
      opts = opts || {};
      var postBody = opts['body'];

      // verify the required parameter 'quoteId' is set
      if (quoteId == undefined || quoteId == null) {
        throw "Missing the required parameter 'quoteId' when calling submitQuote";
      }

      // verify the required parameter 'secret' is set
      if (secret == undefined || secret == null) {
        throw "Missing the required parameter 'secret' when calling submitQuote";
      }


      var pathParams = {
        'quoteId': quoteId
      };
      var queryParams = {
        'secret': secret
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = [];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = MoverQuoteForMover;

      return this.apiClient.callApi(
        '/movers/quotes/{quoteId}/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
