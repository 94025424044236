/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/Address', 'model/Error'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/Address'), require('../model/Error'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.AddressApi = factory(root.JedemenagecaRestApi.ApiClient, root.JedemenagecaRestApi.Address, root.JedemenagecaRestApi.Error);
  }
}(this, function(ApiClient, Address, Error) {
  'use strict';

  /**
   * Address service.
   * @module api/AddressApi
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new AddressApi. 
   * @alias module:api/AddressApi
   * @class
   * @param {module:ApiClient} apiClient Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the geocodeAddress operation.
     * @callback module:api/AddressApi~geocodeAddressCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Address} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Geocode (find the latitude/longitude) of an address.
     * @param {Object} opts Optional parameters
     * @param {module:model/Address} opts.address 
     * @param {module:api/AddressApi~geocodeAddressCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Address}
     */
    this.geocodeAddress = function(opts, callback) {
      opts = opts || {};
      var postBody = opts['address'];


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = Address;

      return this.apiClient.callApi(
        '/addresses/geocode/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the validateAddress operation.
     * @callback module:api/AddressApi~validateAddressCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Address} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Validate or complete an address.
     * @param {Integer} civicNumber Number identifying uniquely a building on a given street.
     * @param {String} postalCode Postal Code (ex. &#39;A1B2C3&#39; for canadian addresses).
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {String} opts.street Name of the street.
     * @param {String} opts.city Name of the city.
     * @param {String} opts.provinceState 2-letter ISO code (lower-cased) of the province or state (ex. qc for Quebec).
     * @param {String} opts.country 2-letter ISO code (lower-cased) of the country (ex. ca for Canada).
     * @param {module:api/AddressApi~validateAddressCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Address}
     */
    this.validateAddress = function(civicNumber, postalCode, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'civicNumber' is set
      if (civicNumber == undefined || civicNumber == null) {
        throw "Missing the required parameter 'civicNumber' when calling validateAddress";
      }

      // verify the required parameter 'postalCode' is set
      if (postalCode == undefined || postalCode == null) {
        throw "Missing the required parameter 'postalCode' when calling validateAddress";
      }


      var pathParams = {
      };
      var queryParams = {
        'civicNumber': civicNumber,
        'street': opts['street'],
        'city': opts['city'],
        'provinceState': opts['provinceState'],
        'country': opts['country'],
        'postalCode': postalCode
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = Address;

      return this.apiClient.callApi(
        '/addresses/validate/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
