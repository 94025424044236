/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/Move', 'model/Error', 'model/Home', 'model/MoveOptions', 'model/InlineResponse2001'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/Move'), require('../model/Error'), require('../model/Home'), require('../model/MoveOptions'), require('../model/InlineResponse2001'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.MoveApi = factory(root.JedemenagecaRestApi.ApiClient, root.JedemenagecaRestApi.Move, root.JedemenagecaRestApi.Error, root.JedemenagecaRestApi.Home, root.JedemenagecaRestApi.MoveOptions, root.JedemenagecaRestApi.InlineResponse2001);
  }
}(this, function(ApiClient, Move, Error, Home, MoveOptions, InlineResponse2001) {
  'use strict';

  /**
   * Move service.
   * @module api/MoveApi
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new MoveApi. 
   * @alias module:api/MoveApi
   * @class
   * @param {module:ApiClient} apiClient Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the createMove operation.
     * @callback module:api/MoveApi~createMoveCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Move} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create a &#x60;Move&#x60; for the authenticated &#x60;User&#x60;. Represents a planned move on a certain date, from an &#x60;Address&#x60; (fromAddress) to another (toAddress). 
     * @param {Object} opts Optional parameters
     * @param {module:model/Move} opts.body &#x60;Move&#x60; to create
     * @param {module:api/MoveApi~createMoveCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Move}
     */
    this.createMove = function(opts, callback) {
      opts = opts || {};
      var postBody = opts['body'];


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = Move;

      return this.apiClient.callApi(
        '/moves/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the createMoveHome operation.
     * @callback module:api/MoveApi~createMoveHomeCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Home} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Creates the &#x60;Home&#x60; &#x60;from&#x60; or &#x60;to&#x60; which the &#x60;Move&#x60; is planned. 
     * @param {String} moveId The unique ID of the &#x60;Move&#x60; to add the &#x60;Home&#x60; to.
     * @param {module:model/String} direction The direction, departure (&#x60;from&#x60;) or arrival (&#x60;to&#x60;) for this &#x60;Home&#x60;.
     * @param {Object} opts Optional parameters
     * @param {module:model/Home} opts.body &#x60;Home&#x60; to create
     * @param {module:api/MoveApi~createMoveHomeCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Home}
     */
    this.createMoveHome = function(moveId, direction, opts, callback) {
      opts = opts || {};
      var postBody = opts['body'];

      // verify the required parameter 'moveId' is set
      if (moveId == undefined || moveId == null) {
        throw "Missing the required parameter 'moveId' when calling createMoveHome";
      }

      // verify the required parameter 'direction' is set
      if (direction == undefined || direction == null) {
        throw "Missing the required parameter 'direction' when calling createMoveHome";
      }


      var pathParams = {
        'moveId': moveId,
        'direction': direction
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = Home;

      return this.apiClient.callApi(
        '/moves/{moveId}/homes/{direction}/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the createMoveOptions operation.
     * @callback module:api/MoveApi~createMoveOptionsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MoveOptions} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Creates &#x60;MoveOptions&#x60; for a &#x60;Move&#x60;. 
     * @param {String} moveId The unique ID of the &#x60;Move&#x60; to add the &#x60;MoveOptions&#x60; to.
     * @param {Object} opts Optional parameters
     * @param {module:model/MoveOptions} opts.body &#x60;MoveOptions&#x60; to create
     * @param {module:api/MoveApi~createMoveOptionsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MoveOptions}
     */
    this.createMoveOptions = function(moveId, opts, callback) {
      opts = opts || {};
      var postBody = opts['body'];

      // verify the required parameter 'moveId' is set
      if (moveId == undefined || moveId == null) {
        throw "Missing the required parameter 'moveId' when calling createMoveOptions";
      }


      var pathParams = {
        'moveId': moveId
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = MoveOptions;

      return this.apiClient.callApi(
        '/moves/{moveId}/options/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getMoveOptions operation.
     * @callback module:api/MoveApi~getMoveOptionsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MoveOptions} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns &#x60;MoveOptions&#x60; of a &#x60;Move&#x60;. 
     * @param {String} moveId The unique ID of the &#x60;Move&#x60; to fetch the &#x60;MoveOptions&#x60; from.
     * @param {module:api/MoveApi~getMoveOptionsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MoveOptions}
     */
    this.getMoveOptions = function(moveId, callback) {
      var postBody = null;

      // verify the required parameter 'moveId' is set
      if (moveId == undefined || moveId == null) {
        throw "Missing the required parameter 'moveId' when calling getMoveOptions";
      }


      var pathParams = {
        'moveId': moveId
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = MoveOptions;

      return this.apiClient.callApi(
        '/moves/{moveId}/options/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getMoves operation.
     * @callback module:api/MoveApi~getMovesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2001} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Return a list of &#x60;Move&#x60;s associated with the authenticated &#x60;User&#x60;, sorted by creation date, descending. 
     * @param {module:api/MoveApi~getMovesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse2001}
     */
    this.getMoves = function(callback) {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse2001;

      return this.apiClient.callApi(
        '/moves/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
