/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.Home = factory(root.JedemenagecaRestApi.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';




  /**
   * The Home model module.
   * @module model/Home
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new <code>Home</code>.
   * The domicile of a person. 
   * @alias module:model/Home
   * @class
   */
  var exports = function() {
    var _this = this;







  };

  /**
   * Constructs a <code>Home</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Home} obj Optional instance to populate.
   * @return {module:model/Home} The populated <code>Home</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('numFloors')) {
        obj['numFloors'] = ApiClient.convertToType(data['numFloors'], 'Integer');
      }
      if (data.hasOwnProperty('buildingType')) {
        obj['buildingType'] = ApiClient.convertToType(data['buildingType'], 'String');
      }
      if (data.hasOwnProperty('id')) {
        obj['id'] = ApiClient.convertToType(data['id'], 'String');
      }
      if (data.hasOwnProperty('floorLevel')) {
        obj['floorLevel'] = ApiClient.convertToType(data['floorLevel'], 'Integer');
      }
      if (data.hasOwnProperty('access')) {
        obj['access'] = ApiClient.convertToType(data['access'], 'String');
      }
      if (data.hasOwnProperty('numRooms')) {
        obj['numRooms'] = ApiClient.convertToType(data['numRooms'], 'Integer');
      }
    }
    return obj;
  }

  /**
   * The number of floors that the `Home` has.
   * @member {Integer} numFloors
   */
  exports.prototype['numFloors'] = undefined;
  /**
   * The kind of building the Home is in, ex. Apartment.
   * @member {module:model/Home.BuildingTypeEnum} buildingType
   */
  exports.prototype['buildingType'] = undefined;
  /**
   * Unique identifier of the Home.
   * @member {String} id
   */
  exports.prototype['id'] = undefined;
  /**
   * In the case of an apartment-condo type of building, the floor level of the `Home`. Ground level is 0, and basements use a negative value.
   * @member {Integer} floorLevel
   */
  exports.prototype['floorLevel'] = undefined;
  /**
   * The way the `Home` can be accessed.
   * @member {module:model/Home.AccessEnum} access
   */
  exports.prototype['access'] = undefined;
  /**
   * The number of rooms in the `Home`.
   * @member {Integer} numRooms
   */
  exports.prototype['numRooms'] = undefined;


  /**
   * Allowed values for the <code>buildingType</code> property.
   * @enum {String}
   * @readonly
   */
  exports.BuildingTypeEnum = {
    /**
     * value: "apartment-condo"
     * @const
     */
    "apartment-condo": "apartment-condo",
    /**
     * value: "bungalow"
     * @const
     */
    "bungalow": "bungalow",
    /**
     * value: "cottage"
     * @const
     */
    "cottage": "cottage",
    /**
     * value: "town-house"
     * @const
     */
    "town-house": "town-house",
    /**
     * value: "semi-detached-bungalow"
     * @const
     */
    "semi-detached-bungalow": "semi-detached-bungalow",
    /**
     * value: "semi-detached-cottage"
     * @const
     */
    "semi-detached-cottage": "semi-detached-cottage"  };

  /**
   * Allowed values for the <code>access</code> property.
   * @enum {String}
   * @readonly
   */
  exports.AccessEnum = {
    /**
     * value: "elevator"
     * @const
     */
    "elevator": "elevator",
    /**
     * value: "outside-staircase"
     * @const
     */
    "outside-staircase": "outside-staircase",
    /**
     * value: "inside-staircase"
     * @const
     */
    "inside-staircase": "inside-staircase",
    /**
     * value: "ground-floor"
     * @const
     */
    "ground-floor": "ground-floor"  };


  return exports;
}));


