/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/InlineResponse2008', 'model/Body3', 'model/Error', 'model/RealtorMembership', 'model/Body2', 'model/Realtor', 'model/RealtorMembershipInvitation', 'model/InlineResponse2009', 'model/InlineResponse20010', 'model/InlineResponse2007', 'model/Event'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/InlineResponse2008'), require('../model/Body3'), require('../model/Error'), require('../model/RealtorMembership'), require('../model/Body2'), require('../model/Realtor'), require('../model/RealtorMembershipInvitation'), require('../model/InlineResponse2009'), require('../model/InlineResponse20010'), require('../model/InlineResponse2007'), require('../model/Event'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.RealtyApi = factory(root.JedemenagecaRestApi.ApiClient, root.JedemenagecaRestApi.InlineResponse2008, root.JedemenagecaRestApi.Body3, root.JedemenagecaRestApi.Error, root.JedemenagecaRestApi.RealtorMembership, root.JedemenagecaRestApi.Body2, root.JedemenagecaRestApi.Realtor, root.JedemenagecaRestApi.RealtorMembershipInvitation, root.JedemenagecaRestApi.InlineResponse2009, root.JedemenagecaRestApi.InlineResponse20010, root.JedemenagecaRestApi.InlineResponse2007, root.JedemenagecaRestApi.Event);
  }
}(this, function(ApiClient, InlineResponse2008, Body3, Error, RealtorMembership, Body2, Realtor, RealtorMembershipInvitation, InlineResponse2009, InlineResponse20010, InlineResponse2007, Event) {
  'use strict';

  /**
   * Realty service.
   * @module api/RealtyApi
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new RealtyApi. 
   * @alias module:api/RealtyApi
   * @class
   * @param {module:ApiClient} apiClient Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the createRealtorClient operation.
     * @callback module:api/RealtyApi~createRealtorClientCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2008} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create (invite) new clients for a &#x60;Realtor&#x60;.
     * @param {String} realtorId 
     * @param {Object} opts Optional parameters
     * @param {module:model/Body3} opts.body 
     * @param {module:api/RealtyApi~createRealtorClientCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse2008}
     */
    this.createRealtorClient = function(realtorId, opts, callback) {
      opts = opts || {};
      var postBody = opts['body'];

      // verify the required parameter 'realtorId' is set
      if (realtorId == undefined || realtorId == null) {
        throw "Missing the required parameter 'realtorId' when calling createRealtorClient";
      }


      var pathParams = {
        'realtorId': realtorId
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse2008;

      return this.apiClient.callApi(
        '/realtors/{realtorId}/clients/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the createRealtorMembership operation.
     * @callback module:api/RealtyApi~createRealtorMembershipCallback
     * @param {String} error Error message, if any.
     * @param {module:model/RealtorMembership} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Join a Realtor&#39;s list of clients.
     * @param {module:model/Body2} body 
     * @param {module:api/RealtyApi~createRealtorMembershipCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/RealtorMembership}
     */
    this.createRealtorMembership = function(body, callback) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling createRealtorMembership";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = RealtorMembership;

      return this.apiClient.callApi(
        '/realtors/membership/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getRealtor operation.
     * @callback module:api/RealtyApi~getRealtorCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Realtor} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a &#x60;Realtor&#x60; by id, or use special id &#39;self&#39; to get the &#x60;Realtor&#x60; associated with the authenticated &#x60;User&#x60;. 
     * @param {String} realtorId 
     * @param {module:api/RealtyApi~getRealtorCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Realtor}
     */
    this.getRealtor = function(realtorId, callback) {
      var postBody = null;

      // verify the required parameter 'realtorId' is set
      if (realtorId == undefined || realtorId == null) {
        throw "Missing the required parameter 'realtorId' when calling getRealtor";
      }


      var pathParams = {
        'realtorId': realtorId
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = Realtor;

      return this.apiClient.callApi(
        '/realtors/{realtorId}/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getRealtorClients operation.
     * @callback module:api/RealtyApi~getRealtorClientsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2008} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get &#x60;Realtor&#x60;&#39;s list of clients.
     * @param {String} realtorId 
     * @param {module:api/RealtyApi~getRealtorClientsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse2008}
     */
    this.getRealtorClients = function(realtorId, callback) {
      var postBody = null;

      // verify the required parameter 'realtorId' is set
      if (realtorId == undefined || realtorId == null) {
        throw "Missing the required parameter 'realtorId' when calling getRealtorClients";
      }


      var pathParams = {
        'realtorId': realtorId
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse2008;

      return this.apiClient.callApi(
        '/realtors/{realtorId}/clients/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getRealtorMembership operation.
     * @callback module:api/RealtyApi~getRealtorMembershipCallback
     * @param {String} error Error message, if any.
     * @param {module:model/RealtorMembership} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get the membership information of a user in regards to &#x60;Realtor&#x60;s&#39; clients. 
     * @param {String} realtorMembershipId The unique id of the RealtorMembership, or special value &#39;self&#39; for &#39;blind lookup&#39;.
     * @param {module:api/RealtyApi~getRealtorMembershipCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/RealtorMembership}
     */
    this.getRealtorMembership = function(realtorMembershipId, callback) {
      var postBody = null;

      // verify the required parameter 'realtorMembershipId' is set
      if (realtorMembershipId == undefined || realtorMembershipId == null) {
        throw "Missing the required parameter 'realtorMembershipId' when calling getRealtorMembership";
      }


      var pathParams = {
        'realtorMembershipId': realtorMembershipId
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = RealtorMembership;

      return this.apiClient.callApi(
        '/realtors/membership/{realtorMembershipId}/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getRealtorMembershipInvitation operation.
     * @callback module:api/RealtyApi~getRealtorMembershipInvitationCallback
     * @param {String} error Error message, if any.
     * @param {module:model/RealtorMembershipInvitation} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get information about the invitation prior to accepting.
     * @param {String} token An invitation token for joining a Realtor&#39;s clients.
     * @param {module:api/RealtyApi~getRealtorMembershipInvitationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/RealtorMembershipInvitation}
     */
    this.getRealtorMembershipInvitation = function(token, callback) {
      var postBody = null;

      // verify the required parameter 'token' is set
      if (token == undefined || token == null) {
        throw "Missing the required parameter 'token' when calling getRealtorMembershipInvitation";
      }


      var pathParams = {
        'token': token
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = RealtorMembershipInvitation;

      return this.apiClient.callApi(
        '/realtors/membership/invitation/{token}/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getRealtorPartnerEvents operation.
     * @callback module:api/RealtyApi~getRealtorPartnerEventsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2009} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get the list of actions done by a &#x60;Realtor&#x60;&#39;s client in relation with his partners.
     * @param {String} realtorId 
     * @param {String} clientId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {module:api/RealtyApi~getRealtorPartnerEventsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse2009}
     */
    this.getRealtorPartnerEvents = function(realtorId, clientId, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'realtorId' is set
      if (realtorId == undefined || realtorId == null) {
        throw "Missing the required parameter 'realtorId' when calling getRealtorPartnerEvents";
      }

      // verify the required parameter 'clientId' is set
      if (clientId == undefined || clientId == null) {
        throw "Missing the required parameter 'clientId' when calling getRealtorPartnerEvents";
      }


      var pathParams = {
        'realtorId': realtorId,
        'clientId': clientId
      };
      var queryParams = {
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse2009;

      return this.apiClient.callApi(
        '/realtors/{realtorId}/clients/{clientId}/events/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getRealtorPartners operation.
     * @callback module:api/RealtyApi~getRealtorPartnersCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20010} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get &#x60;Realtor&#x60;&#39;s list of partners.
     * @param {String} realtorId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {module:api/RealtyApi~getRealtorPartnersCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse20010}
     */
    this.getRealtorPartners = function(realtorId, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'realtorId' is set
      if (realtorId == undefined || realtorId == null) {
        throw "Missing the required parameter 'realtorId' when calling getRealtorPartners";
      }


      var pathParams = {
        'realtorId': realtorId
      };
      var queryParams = {
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse20010;

      return this.apiClient.callApi(
        '/realtors/{realtorId}/partners/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the getRealtorPartnersForMembership operation.
     * @callback module:api/RealtyApi~getRealtorPartnersForMembershipCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2007} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get &#x60;Realtor&#x60;&#39;s list of partners, given a certain Membership and optionally for a single category.
     * @param {String} realtorMembershipId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {String} opts.category 
     * @param {module:api/RealtyApi~getRealtorPartnersForMembershipCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse2007}
     */
    this.getRealtorPartnersForMembership = function(realtorMembershipId, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'realtorMembershipId' is set
      if (realtorMembershipId == undefined || realtorMembershipId == null) {
        throw "Missing the required parameter 'realtorMembershipId' when calling getRealtorPartnersForMembership";
      }


      var pathParams = {
        'realtorMembershipId': realtorMembershipId
      };
      var queryParams = {
        'category': opts['category']
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse2007;

      return this.apiClient.callApi(
        '/realtors/membership/{realtorMembershipId}/partners/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the registerRealtorClientEvent operation.
     * @callback module:api/RealtyApi~registerRealtorClientEventCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Register an event (action) done by a &#x60;Realtor&#x60;&#39;s client, related to a given &#x60;RealtorPartner&#x60;.
     * @param {String} realtorMembershipId 
     * @param {String} realtorPartnerId 
     * @param {Object} opts Optional parameters
     * @param {module:model/Event} opts.event 
     * @param {module:api/RealtyApi~registerRealtorClientEventCallback} callback The callback function, accepting three arguments: error, data, response
     */
    this.registerRealtorClientEvent = function(realtorMembershipId, realtorPartnerId, opts, callback) {
      opts = opts || {};
      var postBody = opts['event'];

      // verify the required parameter 'realtorMembershipId' is set
      if (realtorMembershipId == undefined || realtorMembershipId == null) {
        throw "Missing the required parameter 'realtorMembershipId' when calling registerRealtorClientEvent";
      }

      // verify the required parameter 'realtorPartnerId' is set
      if (realtorPartnerId == undefined || realtorPartnerId == null) {
        throw "Missing the required parameter 'realtorPartnerId' when calling registerRealtorClientEvent";
      }


      var pathParams = {
        'realtorMembershipId': realtorMembershipId,
        'realtorPartnerId': realtorPartnerId
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/realtors/membership/{realtorMembershipId}/partners/{realtorPartnerId}/events/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the resendInvitationToRealtorClient operation.
     * @callback module:api/RealtyApi~resendInvitationToRealtorClientCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Send a new invitation email to a &#x60;Realtor&#x60;&#39;s client.
     * @param {String} realtorId 
     * @param {String} clientId 
     * @param {module:api/RealtyApi~resendInvitationToRealtorClientCallback} callback The callback function, accepting three arguments: error, data, response
     */
    this.resendInvitationToRealtorClient = function(realtorId, clientId, callback) {
      var postBody = null;

      // verify the required parameter 'realtorId' is set
      if (realtorId == undefined || realtorId == null) {
        throw "Missing the required parameter 'realtorId' when calling resendInvitationToRealtorClient";
      }

      // verify the required parameter 'clientId' is set
      if (clientId == undefined || clientId == null) {
        throw "Missing the required parameter 'clientId' when calling resendInvitationToRealtorClient";
      }


      var pathParams = {
        'realtorId': realtorId,
        'clientId': clientId
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/realtors/{realtorId}/clients/{clientId}/invitation/resend/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
