/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/InlineResponse200', 'model/Error'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/InlineResponse200'), require('../model/Error'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.ConciergeApi = factory(root.JedemenagecaRestApi.ApiClient, root.JedemenagecaRestApi.InlineResponse200, root.JedemenagecaRestApi.Error);
  }
}(this, function(ApiClient, InlineResponse200, Error) {
  'use strict';

  /**
   * Concierge service.
   * @module api/ConciergeApi
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new ConciergeApi. 
   * @alias module:api/ConciergeApi
   * @class
   * @param {module:ApiClient} apiClient Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the getConciergeRequest operation.
     * @callback module:api/ConciergeApi~getConciergeRequestCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse200} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get the status of requests for &#39;concierge&#39; service for the given category.
     * @param {String} category 
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {module:api/ConciergeApi~getConciergeRequestCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse200}
     */
    this.getConciergeRequest = function(category, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'category' is set
      if (category == undefined || category == null) {
        throw "Missing the required parameter 'category' when calling getConciergeRequest";
      }


      var pathParams = {
        'category': category
      };
      var queryParams = {
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse200;

      return this.apiClient.callApi(
        '/concierge/requests/{category}/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the registerConciergeRequest operation.
     * @callback module:api/ConciergeApi~registerConciergeRequestCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse200} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Register a new request for &#39;concierge&#39; service for a specific category.
     * @param {String} category 
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {module:api/ConciergeApi~registerConciergeRequestCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse200}
     */
    this.registerConciergeRequest = function(category, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'category' is set
      if (category == undefined || category == null) {
        throw "Missing the required parameter 'category' when calling registerConciergeRequest";
      }


      var pathParams = {
        'category': category
      };
      var queryParams = {
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse200;

      return this.apiClient.callApi(
        '/concierge/requests/{category}/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
