/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/LargeItem'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./LargeItem'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.MoveOptions = factory(root.JedemenagecaRestApi.ApiClient, root.JedemenagecaRestApi.LargeItem);
  }
}(this, function(ApiClient, LargeItem) {
  'use strict';




  /**
   * The MoveOptions model module.
   * @module model/MoveOptions
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new <code>MoveOptions</code>.
   * Options, or special requirements, for a &#x60;Move&#x60;
   * @alias module:model/MoveOptions
   * @class
   * @param standardOptions {Array.<module:model/MoveOptions.StandardOptionsEnum>} Selectable 'standard' options.
   * @param largeItems {Array.<module:model/LargeItem>} Objects that are large in size, and usually affect the cost when hiring movers.
   * @param customOptions {Array.<String>} Special requirements that are not found in the list of standard options.
   */
  var exports = function(standardOptions, largeItems, customOptions) {
    var _this = this;

    _this['standardOptions'] = standardOptions;
    _this['largeItems'] = largeItems;
    _this['customOptions'] = customOptions;
  };

  /**
   * Constructs a <code>MoveOptions</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MoveOptions} obj Optional instance to populate.
   * @return {module:model/MoveOptions} The populated <code>MoveOptions</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();

      if (data.hasOwnProperty('standardOptions')) {
        obj['standardOptions'] = ApiClient.convertToType(data['standardOptions'], ['String']);
      }
      if (data.hasOwnProperty('largeItems')) {
        obj['largeItems'] = ApiClient.convertToType(data['largeItems'], [LargeItem]);
      }
      if (data.hasOwnProperty('customOptions')) {
        obj['customOptions'] = ApiClient.convertToType(data['customOptions'], ['String']);
      }
    }
    return obj;
  }

  /**
   * Selectable 'standard' options.
   * @member {Array.<module:model/MoveOptions.StandardOptionsEnum>} standardOptions
   */
  exports.prototype['standardOptions'] = undefined;
  /**
   * Objects that are large in size, and usually affect the cost when hiring movers.
   * @member {Array.<module:model/LargeItem>} largeItems
   */
  exports.prototype['largeItems'] = undefined;
  /**
   * Special requirements that are not found in the list of standard options.
   * @member {Array.<String>} customOptions
   */
  exports.prototype['customOptions'] = undefined;


  /**
   * Allowed values for the <code>standardOptions</code> property.
   * @enum {String}
   * @readonly
   */
  exports.StandardOptionsEnum = {
    /**
     * value: "packing-included"
     * @const
     */
    "included": "packing-included"  };


  return exports;
}));


