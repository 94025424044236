const fr = {
	nav: {
		home: 'Accueil',
		login: 'Se connecter',
		about: 'A propos',
		param: 'Param FR'
	},
	login: {
		email: 'Courriel',
		password: 'Mot de passe',
		submit: `m'inscrire`,
		signin: `Me connecter`,
		noAccount: `Vous n'avez pas de compte? <strong>S'inscrire </strong>`,
		forgotPassword: `J'ai oublié mon mot de passe`,
	},
	about: {
		welcome: 'Bonjour About'
	}
};

export default fr;