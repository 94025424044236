<template>
    <div id="hello">
        <h1>{{ msg }}</h1>
        <h2>Essential Links</h2>
        <img :src="$t('img')" alt="">
        <p>{{ $t("welcome") }}</p>
        <p>{{ $d(new Date(), 'short') }}</p>
    </div>
</template>

<script>
	export default {
		name: 'hello',
		data () {
			return {
				msg: 'Welcome to Your Vue.js App'
			}
		},
		i18n: {
			messages: {
				en: {
					welcome: 'Hi',
					img: './dist/images/img-en.jpg'
                },
				fr: {
					welcome: 'Bonjour',
					img: './dist/images/img-fr.jpg'
				}
			}
		}
	}
</script>

<style lang="sass">
    #hello {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }

    h1, h2 {
        font-weight: normal;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }
</style>
