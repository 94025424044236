const en = {
	nav: {
		home: 'Home',
		login: 'Sign in',
		about: 'About',
		param: 'Param EN'
	},
	about: {
		welcome: 'Hi About'
	}
};

export default en;