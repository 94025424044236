// The following line loads the standalone build of Vue instead of the runtime-only build,
// so you don't have to do: import Vue from 'vue/dist/vue'
// This is done with the browser options. For the config, see package.json
import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import i18n from './locale/index';

import VueFloatLabel from 'vue-float-label'
import VueLoadersCss from 'vue-loaders-css'

import request from 'superagent';
import jdmApi from './api/index.js';

require('velocity-animate');

Vue.use(VueFloatLabel);
Vue.use(VueLoadersCss);

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.adminOnly)) {
		if(!localStorage.getItem('token')) {
			next({
				path: '/login',
				query: {
					redirect: to.fullPath
				}
			});
		} else {
			next();
		}
	} else {
		next();
	}
});

new Vue({
    //define the selector for the root component
	el: '#app',
	//pass the template to the root component
	template: '<App/>',
	//declare components that the root component can access
	components: { App },
	//pass in the router to the Vue instance
	router,
	i18n
}).$mount('#app'); //mount the router on the app