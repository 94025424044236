/**
 * jedemenage.ca REST API
 * API specification based on [OpenAPI](https://www.openapis.org/) (Swagger). 
 *
 * OpenAPI spec version: 2.13.8-77-g9294f74
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/InlineResponse2002'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/InlineResponse2002'));
  } else {
    // Browser globals (root is window)
    if (!root.JedemenagecaRestApi) {
      root.JedemenagecaRestApi = {};
    }
    root.JedemenagecaRestApi.AdApi = factory(root.JedemenagecaRestApi.ApiClient, root.JedemenagecaRestApi.InlineResponse2002);
  }
}(this, function(ApiClient, InlineResponse2002) {
  'use strict';

  /**
   * Ad service.
   * @module api/AdApi
   * @version 2.13.8-77-g9294f74
   */

  /**
   * Constructs a new AdApi. 
   * @alias module:api/AdApi
   * @class
   * @param {module:ApiClient} apiClient Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;


    /**
     * Callback function to receive the result of the getAds operation.
     * @callback module:api/AdApi~getAdsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse2002} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get localized &#x60;Ad&#x60;s that apply to this &#x60;Move&#x60;, filtered by category. 
     * @param {String} moveId The unique ID of the &#x60;Move&#x60;.
     * @param {String} placement The placement for which &#x60;Ad&#x60;s are fetched.
     * @param {Object} opts Optional parameters
     * @param {String} opts.acceptLanguage Standard http header that allows a client to request the resource in a specific locale (ex. en-CA). If omitted, all resources default to \&quot;Canadian English\&quot; (en-CA).  (default to en-CA)
     * @param {module:api/AdApi~getAdsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse2002}
     */
    this.getAds = function(moveId, placement, opts, callback) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'moveId' is set
      if (moveId == undefined || moveId == null) {
        throw "Missing the required parameter 'moveId' when calling getAds";
      }

      // verify the required parameter 'placement' is set
      if (placement == undefined || placement == null) {
        throw "Missing the required parameter 'placement' when calling getAds";
      }


      var pathParams = {
        'moveId': moveId
      };
      var queryParams = {
        'placement': placement
      };
      var headerParams = {
        'Accept-Language': opts['acceptLanguage']
      };
      var formParams = {
      };

      var authNames = ['oauth2', 'jwt'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = InlineResponse2002;

      return this.apiClient.callApi(
        '/moves/{moveId}/ads/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
  };

  return exports;
}));
